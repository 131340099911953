<template>
  <div>
    <b-modal
      id="add-dns-modal"
      ref="add-dns-modal"
      no-close-on-backdrop
      ok-title="確認"
      centered
      header-bg-variant="primary"
      ok-only
      @hidden="handleHide"
      @close="handleHide"
      @ok="handleOk"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          新增DNS紀錄
        </h4>
      </template>

      <div>
        <b-form
          @reset.prevent="resetForm"
          @submit.prevent="handleOk"
        >
          <b-form-group
            label="類型"
            label-for="branch_id"
          >
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  類型
                  <span class="text-danger">*</span>
                </label>
              </div>
            </template>

            <v-select
              v-model="searchType"
              :options="typeOptions"
              class="w-100"
              clearable
              placeholder="請選擇類型"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-form-group>

          <component
            :is="ComponentType"
            v-if="searchType"
            ref="componentType"
            :source-type="tableType"
          />
        </b-form>
      </div>

      <template #modal-footer>
        <div class="w-100 text-right">
          <b-button
            variant="primary"
            :disabled="!searchType || isBusy"
            @click="handleOk"
          >
            <span v-if="!isBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, VBTooltip, BForm, BFormGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useDomainDNSTable } from '../../useDomain'
import ComponentTypeA from './components/typeA.vue'
import ComponentTypeAaaa from './components/typeAAAA.vue'
import ComponentTypeNs from './components/typeNS.vue'
import ComponentTypeCname from './components/typeCName.vue'
import ComponentTypeDynamic from './components/typeDynamic.vue'
import ComponentTypeMx from './components/typeMx.vue'
import ComponentTypeSrv from './components/typeSrv.vue'
import ComponentTypeTxt from './components/typeTxt.vue'

export default {
  components: {
    BForm,
    BModal,
    BButton,
    BSpinner,
    BFormGroup,
    vSelect,

    ComponentTypeA,
    ComponentTypeAaaa,
    ComponentTypeNs,
    ComponentTypeCname,
    ComponentTypeDynamic,
    ComponentTypeMx,
    ComponentTypeSrv,
    ComponentTypeTxt,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    tableType: {
      type: String,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      missingFields: [],
    }
  },
  computed: {
    ComponentType() {
      if (this.searchType === 'A') return 'component-type-a'
      if (this.searchType === 'AAAA') return 'component-type-aaaa'
      if (this.searchType === 'CNAME') return 'component-type-cname'
      if (this.searchType === 'NS') return 'component-type-ns'
      if (this.searchType === 'DYNAMIC') return 'component-type-dynamic'
      if (this.searchType === 'SRV') return 'component-type-srv'
      if (this.searchType === 'MX') return 'component-type-mx'
      if (this.searchType === 'TXT') return 'component-type-txt'
      return null
    },
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$nextTick(() => {
        this.$bvModal.hide('add-dns-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.isBusy) return

      const typeData = this.$refs.componentType.checkData()
      if (!typeData.state) return

      const resolveData = {
        source: this.tableType,
        type: this.searchType,
        ...typeData.data,
      }

      this.isBusy = true

      this.setDomainDNSAction({
        action: 'create',
        data: resolveData,
      })
        .then(response => {
          this.useAlertToast(response.data.success, response.data.message)
          this.isBusy = false
          this.$emit('refetch-data')
          this.$nextTick(() => {
            this.$bvModal.hide('add-dns-modal')
          })
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (開啟)新增金流商
    getData() {
      this.$bvModal.show('add-dns-modal')
    },
  },
  setup(props) {
    const {
      isBusy,
      searchType,
      setDomainDNSAction,
    } = useDomainDNSTable(props.tableType)

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      isBusy,
      searchType,
      useAlertToast,
      useHttpCodeAlert,
      setDomainDNSAction,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
</style>
