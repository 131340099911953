<template>
  <div>
    <!-- 頁面: 網域詳情(權限: 完成, 註解: 刪除, 優化: 無) -->
    <b-tabs
      v-if="checkAuthAbility('service', 'API.Admin.DomainName.Service.Url')"
      pills
      lazy
      vertical
      content-class="col-12 col-lg-10 mt-1 mt-md-0"
      nav-wrapper-class="col-lg-2 col-12"
      nav-class="nav-left"
    >
      <template slot="default">
        <domain-view-info
          @submit-clear="onSubmitDomainClear"
          @submit-continue="onSubmitDomainContinue"
          @refresh-data="refreshDomainInfoData"
          @refresh-service-data="refreshDomainServiceData"
        />
      </template>

      <div v-if="!adminServiceDomainState.isBusyLoading">
        <!-- tab: 名稱服務器 -->
        <b-tab v-if="adminServiceDomainState.domainInfo.state != 0 && checkAuthAbility('service', 'API.Admin.DomainName.Url.NS')">
          <template #title>
            <b-img
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/service/domain/server.svg')"
            />
            <span class="font-weight-bold">名稱服務器</span>
          </template>

          <domain-view-ns />
        </b-tab>

        <!-- tab: DNS設定 -->
        <b-tab v-if="adminServiceDomainState.domainInfo.state != 0 && checkAuthAbility('service', 'API.Admin.DomainName.Url.DNS')">
          <template #title>
            <b-img
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/service/domain/dns.svg')"
            />
            <span class="font-weight-bold">DNS設定</span>
          </template>

          <domain-view-dns />
        </b-tab>

        <!-- tab: 操作紀錄 -->
        <b-tab v-if="adminServiceDomainState.domainInfo.state != 0 && checkAuthAbility('service', 'API.Admin.DomainName.Url.Log')">
          <template #title>
            <b-img
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/service/domain/history.svg')"
            />
            <span class="font-weight-bold">DNS紀錄</span>
          </template>

          <domain-view-history />
        </b-tab>

        <!-- tab: 交易紀錄 -->
        <b-tab v-if="checkAuthAbility('service', 'API.Admin.DomainName.History')">
          <template #title>
            <b-img
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/service/domain/list.svg')"
            />
            <span class="font-weight-bold">交易紀錄</span>
          </template>

          <domain-view-service
            ref="domainViewService"
            @refresh-data="refreshDomainInfoData"
          />
        </b-tab>

        <!-- tab: CloudFlare設定 -->
        <b-tab v-if="adminServiceDomainState.domainInfo.state != 0 && adminServiceDomainState.domainInfo.reverse_proxy_id && checkAuthAbility('service', 'API.Admin.DomainName.Url.Setting')">
          <template #title>
            <b-img
              height="18"
              width="18"
              class="mr-50 menu-icon"
              :src="require('@/assets/images/pages/service/domain/cloud.svg')"
            />
            <span class="font-weight-bold">CloudFlare設定</span>
          </template>

          <domain-view-cf
            ref="domainViewService"
            @refresh-data="refreshDomainInfoData"
          />
        </b-tab>
      </div>
    </b-tabs>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BTabs, BTab, BImg, BAlert, BLink,
} from 'bootstrap-vue'

import store from '@/store'
import DomainViewInfo from './DomainViewInfo.vue'
import DomainViewDns from './DomainViewDns/DomainViewDns.vue'
import DomainViewNs from './DomainViewNameServerSetting.vue'
import DomainViewService from './DomainViewService.vue'
import DomainViewHistory from './DomainViewHistory.vue'
import DomainViewCf from './DomainViewCloudFlareSetting.vue'
import { useDomainServiceView } from '../useDomain'
import useStoreModule from '../useStoreModule'
import router from '@/router'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BTabs,
    BTab,
    BImg,
    BAlert,
    BLink,
    DomainViewInfo,
    DomainViewDns,
    DomainViewNs,
    DomainViewHistory,
    DomainViewService,
    DomainViewCf,
  },
  mixins: [usePublicFunction],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    adminServiceDomainState() {
      return this.$store.state['admin-service-domain']
    },
  },
  methods: {
    // (觸發)續約
    onSubmitDomainContinue() {
      if (this.isServiceBusy) return
      this.isServiceBusy = true
      const domainInfo = this.$store.getters['admin-service-domain/currentDomainInfo']

      let blankCustomerData = {
        ssid: null,
        id: null,
        image: null,
        account: null,
        name: null,
        family_name: null,
        branch_id: null,
        email_state: 0,
        phone_state: 0,
        kyc_state: 0,
      }

      let blankBranchData = {
        id: null,
        name: null,
        icon: null,
        mail_valid_switch: false,
        phone_valid_switch: false,
      }

      const productArray = []

      let backNum = 3

      const finish = () => {
        const orderData = {
          customer: blankCustomerData,
          branch: blankBranchData,
          product: productArray,
        }
        this.$router.push({ name: 'admin-order-add', params: { queryData: JSON.stringify(orderData) } })
      }

      const resolveProductArray = () => {
        this.getOrderProductSearch({ type: 'domain_continue', queryParams: { branch_id: domainInfo.branch_id } })
          .then(response => {
            const productArrayOptions = response.data.data

            const productItem = productArrayOptions.find(item => item.suffix === domainInfo.suffix)
            if (productItem) {
              const firstPrice = productItem.price.length > 0 ? productItem.price[0] : null
              if (firstPrice) {
                const blankProductData = {
                  id: null,
                  product: productItem.ssid,
                  product_type: 'domain',
                  info: {
                    prefix: domainInfo.prefix,
                    suffix: domainInfo.suffix,
                    quantity: firstPrice.quantity,
                    customer_additional_info: null, // (*)
                  },
                  price: firstPrice.price,
                  type: 'domain_continue',
                  state: 0,
                  comment: '',
                  extra_value: [], // (*)
                  isRuleChange: false, // (*)
                  isImportChange: false, // (*)
                }

                productArray.push(blankProductData)
              }
            }

            backNum -= 1
            if (backNum === 0) finish()
          })
      }

      const resolveCustomer = () => {
        blankCustomerData = {
          ssid: domainInfo.customer,
          ...domainInfo.customer_info,
          branch_id: domainInfo.branch_id,
        }
        backNum -= 1
        if (backNum === 0) finish()
      }

      const resolveBranch = () => {
        blankBranchData = {
          ...domainInfo.branch_info,
        }
        backNum -= 1
        if (backNum === 0) finish()
      }

      resolveProductArray()
      resolveCustomer()
      resolveBranch()
    },

    // (觸發)清除快取
    onSubmitDomainClear() {
    },
  },
  setup() {
    const DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME = 'admin-service-domain'

    if (!store.hasModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DOMAIN_SERVICE_ADMIN_STORE_MODULE_NAME)
    })

    const domainUrl = router.currentRoute.params.id

    const {
      isServiceBusy,
      getDomainInfoData,
      getOrderProductSearch,
    } = useDomainServiceView()

    const domainViewService = ref(null)

    const refreshDomainInfoData = () => {
      store.commit('admin-service-domain/UPDATE_BUSY_LOADING_STATE', true)
      getDomainInfoData(domainUrl, () => {
        store.commit('admin-service-domain/UPDATE_BUSY_LOADING_STATE', false)
      })
    }

    const refreshDomainServiceData = () => {
      if (domainViewService.value) {
        domainViewService.value.refetchData()
      }
    }

    refreshDomainInfoData()

    return {
      isServiceBusy,
      domainUrl,
      domainViewService,
      getOrderProductSearch,
      refreshDomainInfoData,
      refreshDomainServiceData,
    }
  },
}
</script>
