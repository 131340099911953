<template>
  <div>
    <!-- 網域列表 -->
    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              DNS紀錄
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchData"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="創建日期"
                class="d-flex align-items-center actions-link-btn ml-25"
                :class="timeRange ? 'actions-link-btn-active' : null"
                @click="() => { $refs.TableTimeRange.getData(timeRange) }"
              >
                <b-img
                  src="/dashboard/admin/images/table/calendar.svg"
                  class="actions-link-btn-image"
                  rounded
                />

                <TableTimeRange
                  ref="TableTimeRange"
                  table-explorer-id="TableTimeRange"
                  @call-back-data="time => timeRange = time"
                />
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <v-select
              v-model="searchIdentity"
              :options="[
                { label: '管理者', value: 'admin' },
                { label: '會員', value: 'client' },
              ]"
              :reduce="option => option.value"
              placeholder="類型"
              class="type-search-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getDomainListLogData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        show-empty
        class="position-relative"
        details-td-class="p-0"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            hide-header
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 類型 -->
        <template #cell(identity)="data">
          <div class="table-col">
            <b-badge :variant="ui.dnsLogType[1][data.item.identity]">
              {{ ui.dnsLogType[0][data.item.identity] }}
            </b-badge>
          </div>
        </template>

        <!-- 欄位: 標題 -->
        <template #cell(title)="data">
          <div class="table-title">
            <h5 class="mb-0">
              <div class="item-description">
                {{ data.item.title }}
              </div>
            </h5>
          </div>
        </template>

        <!-- 欄位: 內容 -->
        <template #cell(content)="data">
          <div class="table-col text-left">
            <span class="content-text"> {{ data.item.content ? data.item.content : '---' }}</span>
          </div>
        </template>

        <!-- 欄位: IP -->
        <template #cell(ip)="data">
          <div class="table-col">
            {{ data.item.ip === 'system' ? '系統' : data.item.ip }}
          </div>
        </template>

        <!-- 欄位: 裝置 -->
        <template #cell(device)="data">
          <div class="table-col">
            {{ data.item.device }}
          </div>
        </template>

        <!-- 欄位: 會員名稱 -->
        <template #cell(user)="data">
          <div class="table-col">
            <b-media
              v-if="data.item.user && data.item.user_info"
              vertical-align="center"
            >
              <template #aside>
                <b-link @click="linkCreatorInfo(data.item.identity, data.item.user_info.id)">
                  <b-avatar
                    size="32"
                    :src="data.item.user_info.image"
                    :text="avatarText(data.item.user_info.name)"
                    variant="light-primary"
                  />
                </b-link>
              </template>
              <div class="d-block text-nowrap selection-group d-flex align-items-center">
                <b-link
                  class="font-weight-bold d-block text-nowrap show-text"
                  @click="linkCreatorInfo(data.item.identity, data.item.user_info.id)"
                >
                  {{ data.item.user_info.name }}
                </b-link>
              </div>
              <div
                class="text-muted item-description-email"
              >
                {{ data.item.user_info.account }}
              </div>
            </b-media>

            <div
              v-else
              class="text-nowrap"
            >
              <b-avatar
                size="32"
                variant="light-primary"
              />
              <small class="text-muted ml-50">暫無資料</small>
            </div>
          </div>
        </template>

        <!-- 欄位: 創建時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 更新時間 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.updated_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import moment from 'moment'

import {
  BCard, BRow, BTable, BCol, VBTooltip, BDropdown, BInputGroupAppend, BPagination,
  BDropdownForm, BFormInput, BInputGroupPrepend, BImg, BMedia, BLink, BAvatar, BBadge,
  BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { usePublicFunction } from '@/libs/mixins/index'
import { useDomainLog, useDomainSetting } from '../useDomain'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BMedia,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,
    BInputGroupAppend,

    vSelect,
    TableTimeRange,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [usePublicFunction],
  data() {
    return {
      keyArray: [],
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (重新)整理
    refetchServiceData() {
      this.$emit('refresh-data')
      this.refetchData()
    },

    // (前往)訂單建立者
    linkCreatorInfo(type, id) {
      if (type === 'admin') {
        const auth = this.checkAuthAbility('admin', 'API.Admin.User.Id')
        if (!auth) return
        this.$router.push({ name: 'admin-user-view', params: { id } })
        return
      }

      if (type === 'client') {
        const auth = this.checkAuthAbility('customer', 'API.Admin.Customer.Id')
        if (!auth) return
        this.$router.push({ name: 'admin-customer-view', params: { id } })
      }
    },
  },
  setup() {
    const {
      ui,
      domainActionOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDomainSetting()

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchIdentity,
      timeRange,
      syncObject,
      refetchData,
      refetchTable,
      getDomainListLogData,
      useAlertToast,
      useHttpCodeAlert,
    } = useDomainLog()

    return {
      ui,
      domainActionOptions,
      avatarText,
      refonlineTime,
      onlineTime,
      updateOnline,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchIdentity,
      timeRange,
      syncObject,
      refetchData,
      refetchTable,
      getDomainListLogData,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss" scoped>
.item-description-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
}

.selection-group:hover {
  .selection-btn-icon-show {
    opacity: 1;
  }
}

.type-search-select {
  width: 180px;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.content-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 200px;
  display: block;
}

@media (max-width: 768px) {
  .type-search-select {
    width: 100%;
  }
  .content-text {
    min-width: none;
    display: contents;
  }

}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
