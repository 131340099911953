<template>
  <div>
    <b-overlay
      :show="isServiceBusy"
      variant="transparent"
      opacity="0.85"
      rounded="sm"
      blur="5px"
    >
      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                CloudFlare環境設定
                <b-img
                  v-if="isSaveCheck"
                  v-b-tooltip.hover.focus.v-secondary
                  title="尚未儲存"
                  class="alert-icon"
                  :src="require('@/assets/images/commen/warning-yellow.svg')"
                />
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="儲存"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <!-- @click="onSubmitUpdate" -->
                  <b-img
                    src="/dashboard/admin/images/table/save.svg"
                    class="actions-link-btn-image"
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refreshtDomainServiceSetting"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </b-col>

          </b-row>

          <div class="mt-2">
            <!-- 瀏覽器快取TTL -->
            <b-form-group
              label-for="browser_cache_ttl"
              :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 3"
            >
              <template #label>
                <div>
                  <label class="mb-0 setting-title">
                    瀏覽器快取TTL
                  </label>
                </div>
              </template>

              <div v-if="!isServiceBusy">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="cloudflareSetting.browser_cache_ttl"
                      :options="ttlOptions"
                      :clearable="false"
                      :reduce="option => option.value"
                      class="table-perPage-select w-100"
                      @input="() => {
                        isSaveCheck = true
                      }"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          沒有<em>{{ search }}</em> 的相關結果
                        </template>

                        <div v-else>
                          <small>暫無資料</small>
                        </div>
                      </template>
                    </v-select>
                  </b-col>
                </b-row>
              </div>
            </b-form-group>

            <!-- IPv6相容性 -->
            <b-form-group
              label-for="ipv6"
              :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 3"
              class="mt-1"
            >
              <template #label>
                <div>
                  <label class="mb-0 setting-title">
                    IPv6相容性
                  </label>
                </div>
              </template>

              <div
                v-if="!isServiceBusy"
                class="mt-25"
              >
                <toggle-button
                  v-model="cloudflareSetting.ipv6"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="60"
                  :height="24"
                  class="m-25"
                  :labels="{checked: '啟用', unchecked: '關閉'}"
                  :sync="true"
                />
              </div>
            </b-form-group>

            <!-- SSL/TLS設定 -->
            <b-form-group
              label-for="ssl"
              :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 3"
            >
              <template #label>
                <div>
                  <label class="mb-0 setting-title">
                    SSL/TLS設定
                  </label>
                </div>
              </template>

              <div v-if="!isServiceBusy">
                <b-form-radio-group
                  v-model="cloudflareSetting.ssl"
                  :options="sslOptions"
                  value-field="value"
                  text-field="text"
                  class="mt-1"
                  @change="() => {
                    isSaveCheck = true
                  }"
                />
              </div>
            </b-form-group>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BImg, BFormGroup,
  BOverlay, BFormRadioGroup,
} from 'bootstrap-vue'
import { ToggleButton } from 'vue-js-toggle-button'
import vSelect from 'vue-select'
import router from '@/router'
import { useDomainServiceView, useDomainSetting } from '../useDomain'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BOverlay,
    BFormGroup,
    BFormRadioGroup,
    vSelect,
    ToggleButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
    }
  },
  setup() {
    const domainUrl = router.currentRoute.params.id

    const {
      isServiceBusy,
      isSaveCheck,
      cloudflareSetting,
      getDomainServiceSetting,
    } = useDomainServiceView()

    const {
      ttlOptions,
      sslOptions,
    } = useDomainSetting()

    const refreshtDomainServiceSetting = () => {
      getDomainServiceSetting(domainUrl)
    }

    refreshtDomainServiceSetting()

    return {
      isServiceBusy,
      isSaveCheck,
      cloudflareSetting,
      refreshtDomainServiceSetting,
      ttlOptions,
      sslOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.alert-icon {
  position: relative;
  top: -2px;
  width: 26px;
  transform: scale(1.05);
  animation: btn-breathe .8s linear infinite;
}
</style>
