<template>
  <div class="animate__animated animate__fadeIn">
    <!-- DNS列表 -->
    <b-tabs
      pills
      lazy
    >
      <!-- tab: 原經銷商 -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="GlobeIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">原經銷商</span>
        </template>

        <domain-view-dns-table
          type="original"
        />
      </b-tab>

      <!-- tab: 反向代理 -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="GitPullRequestIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">反向代理</span>
        </template>

        <domain-view-dns-table
          type="reverse"
        />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'

import DomainViewDnsTable from './DomainViewDnsTable.vue'

export default {
  components: {
    BTabs,
    BTab,

    DomainViewDnsTable,
  },
}
</script>
