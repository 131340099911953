var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 名稱 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"name","trim":"","autofocus":"","placeholder":"請輸入名稱"},model:{value:(_vm.typeData.name),callback:function ($$v) {_vm.$set(_vm.typeData, "name", $$v)},expression:"typeData.name"}}),_c('div',{staticClass:"mt-25"},[_c('small',{staticClass:"text-muted mx-25"},[_vm._v("根網域使用@")])])],1),_c('b-form-group',{attrs:{"label-for":"contentTxt"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 註釋 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"contentTxt","trim":"","placeholder":"請填寫註釋"},model:{value:(_vm.typeData.contentTxt),callback:function ($$v) {_vm.$set(_vm.typeData, "contentTxt", $$v)},expression:"typeData.contentTxt"}})],1),_c('b-form-group',{attrs:{"label-for":"ttl"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" TTL "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"ttl","type":"number","trim":"","list":"ttl-list"},on:{"change":function () {
        if (_vm.typeData.ttl < 60 && _vm.typeData.ttl != '1') { _vm.typeData.ttl = 60 }
        if (_vm.typeData.ttl > 86400 ) { _vm.typeData.ttl = 86400 }
      },"focus":function($event){_vm.showOptions = true},"blur":_vm.hideOptions},model:{value:(_vm.typeData.ttl),callback:function ($$v) {_vm.$set(_vm.typeData, "ttl", $$v)},expression:"typeData.ttl"}}),(_vm.showOptions)?_c('div',{staticClass:"datalist-options"},_vm._l((_vm.ttlListOptions),function(list,index){return _c('div',{key:index,staticClass:"datalist-option",on:{"click":function($event){_vm.typeData.ttl = list; _vm.showOptions = false}}},[_c('span',{staticClass:"datalist-value mr-50"},[_vm._v(_vm._s(list))]),_c('small',{staticClass:"datalist-label"},[_vm._v("( "+_vm._s(_vm.ui.ttlList[list])+" )")])])}),0):_vm._e()],1),(_vm.sourceType === 'reverse')?_c('b-form-group',{attrs:{"label-for":"proxy"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" Proxy狀態 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,false,2801164504)},[_c('v-select',{staticClass:"w-100",attrs:{"options":[
        { label: '啟用', value: true },
        { label: '關閉', value: false } ],"clearable":false,"reduce":function (option) { return option.value; },"placeholder":"請選擇狀態"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
      var search = ref.search;
      var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,false,1513294008),model:{value:(_vm.typeData.proxy),callback:function ($$v) {_vm.$set(_vm.typeData, "proxy", $$v)},expression:"typeData.proxy"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }