import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    domainInfo: null,
    domainList: [],
  },
  getters: {
    currentDomainInfo: state => {
      const { domainInfo } = state
      return domainInfo
    },
  },
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_DOMAIN_INFO_STATE(state, val) {
      state.domainInfo = val
    },
    UPDATE_DOMAIN_LIST_STATE(state, val) {
      state.domainList = val
    },
  },
  actions: {
    // (查詢)交易列表
    getDomainService(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/domainName/service', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)交易列表
    getDomainList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/domainName', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)服務
    getDomainServiceInfo(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainName/service/${resolveData.url}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)網域服務
    setDomainServiceAdd(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/domainName/service', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)網域服務
    setDomainServiceUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainName/${resolveData.url}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)網域操作紀錄
    getDomainServiceLog(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainName/${resolveData.url}/log`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // --------------------------------手動觸發串接--------------------------------------
    // (網域)
    setOrderServiceDomain(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainName/${resolveData.domain_name_id}/action`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)商品價格
    getOrderProductSearch(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/order/product/${resolveData.type}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------名稱服務器管理-------------------------------------
    // (查詢)名稱服務器列表
    getDomainServiceNameServer(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainName/${resolveData.url}/nameservers`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)名稱服務器
    setDomainServiceNameServerUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainName/${resolveData.url}/nameservers`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------DNS-------------------------------------
    // (查詢)DNS網域服務
    getDomainServiceDNSList(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainName/${resolveData.url}/dns`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)名稱服務器 action: 'create' | 'update' | 'delete'
    setDomainServiceDNSAction(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainName/${resolveData.url}/dns/${resolveData.action}`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)反向代理
    setDomainServiceDNSReverseProxy(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainName/${resolveData.url}/reverseProxy`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (解除)反向代理
    setDomainServiceDNSReverseProxyDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainName/${resolveData.url}/reverseProxy`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ----------------------------CloudFlare-------------------------------------
    // (查詢)CloudFlare設定
    getDomainServiceSetting(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainName/${resolveData.url}/setting`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
