<template>
  <div>
    <b-skeleton-wrapper :loading="adminServiceDomainState.isBusyLoading">
      <template #loading>
        <b-card no-body>
          <b-card-header>
            <h2 class="mb-0">
              {{ $router.currentRoute.params.id }} 的管理 DNS
            </h2>
          </b-card-header>

          <div class="p-2">
            <b-skeleton
              animation="wave"
              width="85%"
            />
            <b-skeleton
              animation="wave"
              width="55%"
            />
            <b-skeleton
              animation="wave"
              width="70%"
            />
          </div>
        </b-card>
      </template>
    </b-skeleton-wrapper>

    <div v-if="!adminServiceDomainState.isBusyLoading">
      <!-- 網域編輯 -->
      <domain-list-edit-modal
        ref="domainEditModal"
        @refetch-data="() => {
          $emit('refresh-data')
          $emit('refresh-service-data')
        }"
      />

      <b-card no-body>
        <!-- 標題 -->
        <b-card-header>
          <h2 class="mb-0">
            {{ adminServiceDomainState.domainInfo.prefix }}{{ adminServiceDomainState.domainInfo.suffix }} 的管理 DNS
          </h2>

          <div class="d-flex">
            <div
              v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
              class="link-remark-botton-alert actions-link-btn mr-25"
              @click="onSubmitRemark()"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="備註留言"
                src="/dashboard/admin/images/table/chat.svg"
                class="actions-link-btn-image"
                rounded
                fluid
              />
              <div
                v-if="adminServiceDomainState.domainInfo.remark_display"
                class="state-dot dot-red"
              />
            </div>

            <div
              v-if="checkAuthAbility('service', 'API.Admin.DomainName.Update')"
              class="actions-link-btn mr-25"
              @click="onSubmitServiceEdit(adminServiceDomainState.domainInfo)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="快速編輯"
                src="/dashboard/admin/images/table/edit.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-b-tooltip.hover.focus.v-secondary
              title="重新整理"
              class="d-flex align-items-center actions-link-btn ml-25"
              @click="() => $emit('refresh-data')"
            >
              <b-img
                src="/dashboard/admin/images/table/refresh.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </b-card-header>

        <!-- 編輯/反向代理 -->
        <b-card-body
          v-if="adminServiceDomainState.domainInfo.state != 0"
          class="pb-0"
        >
          <div class="text-right">
            <b-button
              v-if="checkAuthAbility('service', 'API.Admin.DomainName.Url.Cache.Clear')"
              variant="outline-primary"
              class="ml-1 mb-50 mb-md-0"
              :size="$store.getters['app/currentDeviceType'] === 'mobile' ? 'sm' : 'md'"
              :disabled="isBusy"
              @click="() => $emit('submit-clear')"
            >
              清除快取
            </b-button>

            <b-button
              v-if="checkAuthAbility('order', 'API.Admin.Order.Create')"
              variant="outline-primary"
              class="ml-1 mb-50 mb-md-0"
              :size="$store.getters['app/currentDeviceType'] === 'mobile' ? 'sm' : 'md'"
              :disabled="isBusy"
              @click="() => $emit('submit-continue')"
            >
              續約
            </b-button>

            <b-button
              v-if="checkAuthAbility('service', 'API.Admin.DomainName.Url.ReverseProxy')"
              v-b-modal.agent-modal
              variant="primary mb-50 mb-md-0"
              class="ml-1"
              :size="$store.getters['app/currentDeviceType'] === 'mobile' ? 'sm' : 'md'"
              :disabled="isBusy"
              @click="submitDomainReverseProxy"
            >
              <b-spinner
                v-if="busyType === 'reverse_proxy' && isBusy"
                small
              />

              <span v-else>
                反向代理
              </span>
            </b-button>
          </div>
        </b-card-body>

        <!-- 網域基本訊息 -->
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              xl="5"
              lg="6"
            >
              <b-form-group
                label-cols="4"
                label="網域名稱"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.url }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="約期(年)"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.quantity ? adminServiceDomainState.domainInfo.quantity : '---' }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="當前經銷商"
                class="m-0"
              >
                <div class="mt-50">
                  <span>{{ adminServiceDomainState.domainInfo.domain_name_agent_id !== null ? adminServiceDomainState.domainInfo.domain_name_agent_info.agent : '---' }}</span>
                  <span
                    v-if="adminServiceDomainState.domainInfo.domain_name_dealer_id"
                    v-b-tooltip.hover.focus.v-secondary
                    :title="adminServiceDomainState.domainInfo.domain_name_dealer_info.name"
                    class="ml-50"
                  >
                    <b-img
                      src="/dashboard/admin/images/common/notifyBlue.png"
                      height="22"
                      width="22"
                    />
                  </span>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="反向代理帳號"
                class="m-0"
              >
                <div
                  v-if="adminServiceDomainState.domainInfo.reverse_proxy_id"
                  class="mt-50"
                >
                  <span>{{ adminServiceDomainState.domainInfo.reverse_proxy_id !== null ? adminServiceDomainState.domainInfo.reverse_proxy_info.name : '---' }}</span>

                  <span
                    v-if="adminServiceDomainState.domainInfo.reverse_proxy_id && !isBusy && checkAuthAbility('service', 'API.Admin.DomainName.Url.ReverseProxy.Remove')"
                    v-b-tooltip.hover.focus.v-secondary
                    title="移除反向代理"
                    class="ml-50 cursor-pointer"
                    @click="submitDomainReverseProxyRelieve"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/unlink.svg"
                      class="actions-link-btn-image"
                      rounded
                      height="14"
                      width="14"
                    />
                  </span>
                </div>

                <div
                  v-else
                  class="mt-50"
                >
                  <small class="text-muted">尚未設定</small>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="創建時間"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.created_at ? moment(adminServiceDomainState.domainInfo.created_at).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              xl="5"
              lg="6"
            >
              <b-form-group
                label-cols="4"
                label="狀態"
                class="m-0"
              >
                <div class="mt-50">
                  <b-badge
                    v-if="!isServiceBusy"
                    :variant="ui.stateType[1][adminServiceDomainState.domainInfo.state]"
                    class="cursor-pointer"
                  >
                    {{ ui.stateType[0][adminServiceDomainState.domainInfo.state] }}
                  </b-badge>

                  <b-badge
                    v-else
                    variant="light-secondary"
                  >
                    處理中
                  </b-badge>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="當前會員"
                class="m-0"
              >
                <div
                  v-if="adminServiceDomainState.domainInfo.customer"
                  class="d-block text-nowrap selection-group d-flex align-items-center mt-50"
                >
                  <b-link
                    class="font-weight-bold d-block text-nowrap show-text"
                    @click="onSubmitRemark()"
                  >
                    {{ adminServiceDomainState.domainInfo.customer_info.name }} {{ adminServiceDomainState.domainInfo.customer_info.family_name }}
                  </b-link>

                  <b-link
                    v-b-tooltip.hover.focus.v-secondary
                    title="會員詳情"
                    class="selection-btn-icon-show"
                    :to="{ name: 'admin-customer-view', params: { id: adminServiceDomainState.domainInfo.customer_info.id } }"
                  >
                    <b-img
                      v-if="checkAuthAbility('customer', 'API.Admin.Customer.Id')"
                      src="/dashboard/admin/images/table/external-link.svg"
                      height="14"
                      width="14"
                      class="cursor-pointer mb-25"
                    />
                  </b-link>
                </div>

                <div
                  v-else
                  class="text-nowrap mt-50"
                >
                  <small class="text-muted ml-50">暫無資料</small>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="開始時間"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.id && adminServiceDomainState.domainInfo.start_time ? moment(adminServiceDomainState.domainInfo.start_time).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="到期時間"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.id && adminServiceDomainState.domainInfo.end_time ? moment(adminServiceDomainState.domainInfo.end_time).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="最後更新"
                class="m-0"
              >
                <div class="mt-50">
                  <div v-if="adminServiceDomainState.domainInfo.updated_at">
                    <span>
                      {{ updateOnline(adminServiceDomainState.domainInfo.updated_at, refonlineTime) === moment(adminServiceDomainState.domainInfo.updated_at).format("YYYY-MM-DD") ? moment(adminServiceDomainState.domainInfo.updated_at).format("YYYY-MM-DD HH:mm") : updateOnline(adminServiceDomainState.domainInfo.updated_at, refonlineTime) }}
                    </span>
                  </div>

                  <div v-else>
                    <span>---</span>
                  </div>
                </div>
              </b-form-group>

            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <remark-modal
      v-if="keyArray.length > 0"
      ref="domainInfoRemarkExplorerModal"
      remark-explorer-id="domainInfoRemarkExplorerModal"
      :key-array="keyArray"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="() => $emit('refresh-data')"
      @call-back-data="() => {}"
    >
      <template v-slot:header-info>
        <div
          v-if="adminServiceDomainState.domainInfo && adminServiceDomainState.domainInfo.customer"
          class="p-2"
        >
          <b-row>
            <b-col
              cols="21"
              lg="6"
              class="d-flex justify-content-between flex-column mb-1"
            >
              <div class="d-flex justify-content-start">
                <div class="profile-image">
                  <b-avatar
                    size="9rem"
                    rounded
                    :src="adminServiceDomainState.domainInfo.customer_info.image"
                    variant="light-secondary"
                  />
                </div>

                <div class="d-flex flex-column ml-1">
                  <div class="mb-50">
                    <h2 class="mb-0">
                      {{ adminServiceDomainState.domainInfo.customer_info.name }} {{ adminServiceDomainState.domainInfo.customer_info.family_name }}
                    </h2>
                    <span class="show-text">{{ adminServiceDomainState.domainInfo.customer_info.account }}</span>
                  </div>

                  <div class="text-nowrap">
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="信箱驗證"
                      rounded
                      fluid
                      class="user-state-icon"
                      :style="{ 'opacity': adminServiceDomainState.domainInfo.customer_info.email_state === 2 ? 1 : 0.3 }"
                      :src="require('@/assets/images/pages/customer/email.svg')"
                    />

                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="電話驗證"
                      rounded
                      fluid
                      class="user-state-icon"
                      :style="{ 'opacity': adminServiceDomainState.domainInfo.customer_info.phone_state === 2 ? 1 : 0.3 }"
                      width="28"
                      :src="require('@/assets/images/pages/customer/security.svg')"
                    />

                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="身分驗證"
                      rounded
                      fluid
                      class="user-state-icon"
                      :style="{ 'opacity': adminServiceDomainState.domainInfo.customer_info.kyc_state === 2 ? 1 : 0.3 }"
                      :src="require('@/assets/images/pages/customer/id-card.svg')"
                    />

                  </div>
                </div>
              </div>
            </b-col>

            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label-cols="4"
                label="當前網域"
                class="m-0"
              >
                <div class="mt-50 font-weight-bolder">
                  {{ adminServiceDomainState.domainInfo.prefix }}{{ adminServiceDomainState.domainInfo.suffix }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="上次登入"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.customer_info.last_login_at ? moment(adminServiceDomainState.domainInfo.customer_info.last_login_at).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="上次更新"
                class="m-0"
              >
                <div class="mt-50">
                  {{ adminServiceDomainState.domainInfo.customer_info.updated_at ? moment(adminServiceDomainState.domainInfo.customer_info.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div
          v-else
          class="mb-1"
        />
      </template>
    </remark-modal>

    <admin-domain-dealer-modal
      ref="adminDomainDealerExplorerModal"
      domain-dealer-explorer-id="adminDomainDealerExplorerModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      :filter-agent="['CloudFlare']"
      @emit-hidden-function="() => {}"
      @call-back-data="callbacDomainDealerExplorer"
    />
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, VBToggle, VBTooltip, BRow, BCol, BFormGroup, BImg, BButton, BSpinner,
  BBadge, BLink, BSkeletonWrapper, BSkeleton, BAvatar,
} from 'bootstrap-vue'
import moment from 'moment'
import router from '@/router'
import store from '@/store'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import { useDomainServiceView, useDomainSetting, useDomainServiceList } from '../useDomain'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'
import DomainListEditModal from '../domain-list/DomainListEditModal.vue'
import AdminDomainDealerModal from '@/layouts/components/Modal/admin-domain-dealer-modal/domainDealerModal.vue'

export default {
  components: {
    BImg,
    BCard,
    BLink,
    BBadge,
    BButton,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BFormGroup,
    BSkeletonWrapper,
    BSkeleton,
    remarkModal,
    DomainListEditModal,
    AdminDomainDealerModal,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      keyArray: [],
      isBusy: false,
      busyType: null,
    }
  },
  computed: {
    adminServiceDomainState() {
      return this.$store.state['admin-service-domain']
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)備註
    onSubmitRemark() {
      const auth = this.checkAuthAbility('remark', 'API.Admin.Remark')
      if (!auth) return
      const resolveArray = []
      resolveArray.push(`domain-${this.domainUrl}`)
      if (this.adminServiceDomainState.domainInfo.customer) resolveArray.push(`customer-${this.adminServiceDomainState.domainInfo.customer_info.id}`)
      if (resolveArray.length <= 0) return
      this.keyArray = resolveArray
      setTimeout(() => { this.$refs.domainInfoRemarkExplorerModal.getData() }, 200)
    },

    // (編輯)網域
    onSubmitServiceEdit(item) {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Update')
      if (!auth) return
      setTimeout(() => { this.$refs.domainEditModal.getData(item) }, 200)
    },

    // (觸發)反向代理
    submitDomainReverseProxy() {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Url.ReverseProxy')
      if (!auth) return
      this.$refs.adminDomainDealerExplorerModal.getData({ ...this.adminServiceDomainState.domainInfo.reverse_proxy_info, domain_name_agent_id: this.adminServiceDomainState.domainInfo.reverse_proxy_agent_id })
    },

    // (解除)反向代理
    submitDomainReverseProxyRelieve() {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Url.ReverseProxy.Remove')
      if (!auth) return
      if (this.isBusy) return

      this.useSwalAlertWarning('解除反向代理', '你確定要觸發移除該網域的反向代理帳號嗎?')
        .then(result => {
          if (result.value) {
            this.isBusy = true
            this.busyType = 'reverse_proxy'
            this.setDomainReverseProxyDelete({
              url: this.domainUrl,
            })
              .then(() => {
                this.useSwalAlertCenter(true, '觸發成功', '該網域反向代理帳號已被解除')
                const resolveData = {
                  ...this.$store.state['admin-service-domain'].domainInfo,
                  reverse_proxy_id: null,
                  reverse_proxy_info: { ...this.blankDealerInfoData },
                  reverse_proxy_agent_info: { ...this.blankDomainAgent },
                  updated_at: new Date(),
                }
                store.commit('admin-service-domain/UPDATE_DOMAIN_INFO_STATE', resolveData)
                this.$emit('refresh-data')
                this.isBusy = false
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '觸發失敗', error.response.data.message)
                this.isBusy = false
              })
          }
        })
    },

    // (回傳)經銷商
    callbacDomainDealerExplorer(item, agent) {
      this.isBusy = true
      this.busyType = 'reverse_proxy'

      this.setDomainReverseProxy({
        url: this.domainUrl,
        data: {
          reverse_proxy_id: item.id,
        },
      })
        .then(() => {
          this.useSwalAlertCenter(true, '觸發成功', '該網域已綁定反向代理帳號')
          const resolveData = {
            ...this.$store.state['admin-service-domain'].domainInfo,
            reverse_proxy_id: item.id,
            reverse_proxy_info: { ...item },
            reverse_proxy_agent_info: { ...agent },
            updated_at: new Date(),
          }
          store.commit('admin-service-domain/UPDATE_DOMAIN_INFO_STATE', resolveData)
          this.$emit('refresh-data')
          this.isBusy = false
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '觸發失敗', error.response.data.message)
          this.isBusy = false
        })
    },

    async checkDomainStatusRepeatedly(attempt = 1) {
      if (attempt > 10) {
        this.$emit('refresh-data')
        this.$emit('refresh-service-data')
        this.useSwalAlertCenter(true, '串接失敗', '服務啟用失敗，請稍後再嘗試')
        this.isServiceBusy = false
        return
      }

      const serviceData = await new Promise(resolve => {
        this.checkDomainInfoState(resolve)
      })

      if (!serviceData || serviceData.state !== 0) {
        this.$emit('refresh-data')
        this.$emit('refresh-service-data')
        this.isServiceBusy = false
        return
      }

      await new Promise(resolve => setTimeout(resolve, 3000))

      this.checkDomainStatusRepeatedly(attempt + 1)
    },

  },
  setup() {
    const {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDomainSetting()

    const {
      blankDealerInfoData,
      blankDomainAgent,
    } = useDomainServiceList()

    const {
      isServiceBusy,
      setOrderServiceDomain,
      checkDomainInfoState,
      setDomainReverseProxy,
      setDomainReverseProxyDelete,
    } = useDomainServiceView()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const domainUrl = router.currentRoute.params.id

    return {
      ui,
      domainUrl,
      refonlineTime,
      onlineTime,
      updateOnline,
      isServiceBusy,
      useAlertToast,
      useHttpCodeAlert,
      setOrderServiceDomain,
      setDomainReverseProxy,
      setDomainReverseProxyDelete,
      checkDomainInfoState,
      blankDealerInfoData,
      blankDomainAgent,
    }
  },
}
</script>

<style lang="scss" scoped>
.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
}

.selection-group:hover {
  .selection-btn-icon-show {
    opacity: 1;
  }
}
.user-state-icon {
  max-width: 30px;
  margin-right: 10px;
}
</style>
