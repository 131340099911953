<template>
  <div>
    <!-- 名稱服務器 -->
    <b-overlay
      :show="isCardBusy"
      variant="transparent"
      opacity="0.85"
      rounded="sm"
      blur="5px"
    >
      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                名稱服務器
                <b-img
                  v-if="isSaveCheck"
                  v-b-tooltip.hover.focus.v-secondary
                  title="尚未儲存"
                  class="alert-icon"
                  :src="require('@/assets/images/commen/warning-yellow.svg')"
                />
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('service', 'API.Admin.DomainName.Url.NS.Update')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitAdd"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-if="checkAuthAbility('service', 'API.Admin.DomainName.Url.NS.Update')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="儲存"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="onSubmitUpdate"
                >
                  <b-img
                    src="/dashboard/admin/images/table/save.svg"
                    class="actions-link-btn-image"
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="() => {
                    onSubmitRefresh()
                    getDomainNameServer()
                  }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <div>
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <!-- 忙碌中 -->
              <tbody v-if="isTableBusy">
                <b-skeleton-table
                  :rows="5"
                  :columns="1"
                  hide-header
                  :table-props="{ bordered: true, striped: true }"
                />
              </tbody>

              <!-- 查無資料 -->
              <tbody v-else-if="serviceNameServer.length === 0">
                <div class="text-center my-5 animate__animated animate__fadeIn">
                  <b-img
                    :src="$store.state.app.themeImages.notFoundImg"
                    fluid
                    width="480"
                    alt="查無資料"
                  />
                </div>
              </tbody>

              <tbody v-else>
                <tr
                  v-for="(item, index) in serviceNameServer"
                  :key="index"
                >
                  <!-- 欄位: 序號 -->
                  <td>
                    <h4 class="m-0 py-50">
                      {{ index + 1 }}
                    </h4>
                  </td>

                  <!-- 欄位: 名稱 -->
                  <td>
                    <b-form-group class="edit-col-from-group mb-0">
                      <b-input-group
                        v-if="selectIndex === index"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="selectText"
                          :placeholder="item ? item : '請輸入名稱服務器'"
                          @keyup.enter="confirmEditCol"
                        />

                        <b-input-group-append is-text>
                          <div>
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </b-input-group-append>
                      </b-input-group>

                      <div
                        v-else
                        class="edit-col-label"
                        @click="selectEditCol(index)"
                      >
                        <h4 class="m-0 py-50">
                          {{ item }}
                        </h4>

                      </div>
                    </b-form-group>
                  </td>

                  <td class="d-flex text-nowrap justify-content-end">
                    <div
                      v-if="selectIndex !== index && checkAuthAbility('service', 'API.Admin.DomainName.Url.NS.Update')"
                      class="actions-link-btn mr-25"
                      @click="selectEditCol(index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="編輯"
                        src="/dashboard/admin/images/table/edit.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      v-if="checkAuthAbility('service', 'API.Admin.DomainName.Url.NS.Update')"
                      class="actions-link-btn mr-25"
                      @click="onSubmitDelete(item, index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="刪除"
                        src="/dashboard/admin/images/table/delete.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BImg, BSkeletonTable, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue'

import { useDomainNameServer } from '../useDomain'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BOverlay,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BSkeletonTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [usePublicFunction],
  data() {
    return {
      selectIndex: null,
      selectText: null,
      isSaveCheck: false,
    }
  },
  methods: {
    // (選取)編輯欄位
    selectEditCol(index) {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Url.NS.Update')
      if (!auth) return
      this.selectIndex = index
      this.selectText = JSON.parse(JSON.stringify(this.serviceNameServer[index]))
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.selectIndex = null
    },

    // (確認)編輯欄位
    confirmEditCol() {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Url.NS.Update')
      if (!auth) return
      this.serviceNameServer[this.selectIndex] = JSON.parse(JSON.stringify(this.selectText))
      this.selectIndex = null
      this.selectText = null
      this.isSaveCheck = true
    },

    // (新增)欄位
    onSubmitAdd() {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Url.NS.Update')
      if (!auth) return
      if (this.selectIndex) return
      this.selectText = null
      this.serviceNameServer.push('')
      this.selectIndex = this.serviceNameServer.length - 1
      this.isSaveCheck = true
    },

    // (移除)欄位
    onSubmitDelete(item, index) {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Url.NS.Update')
      if (!auth) return
      this.$swal({
        title: '移除名稱服務器',
        text: `你確定要移除設定 ${item} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.serviceNameServer.splice(index, 1)
          this.isSaveCheck = true
        }
      })
    },

    // (儲存)欄位
    onSubmitUpdate() {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Url.NS.Update')
      if (!auth) return
      if (this.isCardBusy) return
      this.isCardBusy = true
      this.$swal({
        title: '儲存名稱服務器',
        text: '更新名稱伺服器可能影響服務運行，確定要執行變更嗎？',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.setDomainNameServerUpdate({
            data: this.serviceNameServer,
          })
            .then(response => {
              this.useAlertToast(response.data.success, response.data.message)
              this.selectIndex = null
              this.selectText = null
              this.isSaveCheck = false
              this.isCardBusy = false
            })
            .catch(error => {
              this.useHttpCodeAlert(error.response)
              this.isCardBusy = false
            })
        } else this.isCardBusy = false
      })
    },

    // (重整)欄位
    onSubmitRefresh() {
      this.isSaveCheck = false
      this.cancelEditCol()
    },
  },
  setup() {
    const {
      isTableBusy,
      isCardBusy,
      serviceNameServer,

      setDomainNameServerUpdate,
      getDomainNameServer,

      useAlertToast,
      useHttpCodeAlert,
    } = useDomainNameServer()

    getDomainNameServer()

    return {
      isTableBusy,
      isCardBusy,
      serviceNameServer,

      setDomainNameServerUpdate,
      getDomainNameServer,

      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss" scoped>
.alert-icon {
  position: relative;
  top: -2px;
  width: 26px;
  transform: scale(1.05);
  animation: btn-breathe .8s linear infinite;
}
</style>
