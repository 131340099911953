<template>
  <div>
    <b-form-group label-for="name">
      <template #label>
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">
            名稱
            <span class="text-danger">*</span>
          </label>
        </div>
      </template>

      <b-form-input
        id="name"
        v-model="typeData.name"
        trim
        autofocus
        placeholder="請輸入名稱"
      />
      <div class="mt-25">
        <small class="text-muted mx-25">根網域使用@</small>
      </div>
    </b-form-group>

    <b-form-group label-for="contentTxt">
      <template #label>
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">
            內容
            <span class="text-danger">*</span>
          </label>
        </div>
      </template>

      <b-form-input
        id="contentTxt"
        v-model="typeData.contentTxt"
        trim
        placeholder="請填寫內容"
      />
    </b-form-group>

    <b-form-group label-for="ttl">
      <template #label>
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">
            TTL
            <span class="text-danger">*</span>
          </label>
        </div>
      </template>

      <b-form-input
        id="ttl"
        v-model="typeData.ttl"
        type="number"
        trim
        list="ttl-list"
        @change="() => {
          if (typeData.ttl < 60 && typeData.ttl != '1') typeData.ttl = 60
          if (typeData.ttl > 86400 ) typeData.ttl = 86400
        }"
        @focus="showOptions = true"
        @blur="hideOptions"
      />

      <div
        v-if="showOptions"
        class="datalist-options"
      >
        <div
          v-for="(list, index) in ttlListOptions"
          :key="index"
          class="datalist-option"
          @click="typeData.ttl = list; showOptions = false"
        >
          <span class="datalist-value mr-50">{{ list }}</span>
          <small class="datalist-label">( {{ ui.ttlList[list] }} )</small>
        </div>
      </div>

      <!-- <datalist id="ttl-list">
        <option
          v-for="(list, index) in ttlListOptions"
          :key="index"
          :value="`${list} ( ${ui.ttlList[list]} )`"
          @click="test(list)"
        >
        </option>
      </datalist> -->
    </b-form-group>

    <b-form-group
      v-if="sourceType === 'reverse'"
      label-for="proxy"
    >
      <template #label>
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">
            Proxy狀態
            <span class="text-danger">*</span>
          </label>
        </div>
      </template>

      <v-select
        v-model="typeData.proxy"
        :options="[
          { label: '啟用', value: true },
          { label: '關閉', value: false },
        ]"
        class="w-100"
        :clearable="false"
        :reduce="option => option.value"
        placeholder="請選擇狀態"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            沒有<em>{{ search }}</em> 的相關結果
          </template>

          <div v-else>
            <small>暫無資料</small>
          </div>
        </template>
      </v-select>
    </b-form-group>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { VBTooltip, BFormGroup, BFormInput } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useDomainSetting } from '../../../useDomain'

export default {
  components: {
    BFormGroup,
    BFormInput,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    sourceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showOptions: false,
      missingFields: [],
    }
  },
  methods: {
    hideOptions() {
      setTimeout(() => {
        this.showOptions = false
      }, 300)
    },

    // (檢查)資料
    checkData() {
      const resolveData = {
        state: false,
        data: this.typeData,
      }

      const requiredFields = {
        name: '名稱',
        ttl: 'TTL ',
        contentTxt: '內容 ',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.typeData[Object.keys(requiredFields)[i]] === null || this.typeData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return resolveData
      }

      resolveData.state = true

      return resolveData
    },

    // (獲取)資料
    getData(info) {
      this.typeData = this.syncObject(this.blankTypeData, info)
    },
  },
  setup() {
    const {
      syncObject,
      ttlListOptions,
      ui,
    } = useDomainSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const typeData = ref(null)

    const blankTypeData = {
      id: null,
      name: null,
      ttl: 60,
      contentTxt: null,
      proxy: false,
    }

    const resetTypeData = () => {
      typeData.value = JSON.parse(JSON.stringify(blankTypeData))
    }

    resetTypeData()

    return {
      ttlListOptions,
      ui,
      syncObject,

      typeData,
      blankTypeData,

      resetTypeData,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>
